<template>
  <input class="form-text" v-model="model" />
</template>

<script lang="ts" setup>
const model = defineModel()
</script>

<style lang="postcss" scoped>
@layer BaseComponents {
  .form-text {
    @apply block w-full rounded bg-white border border-primary-100 text-primary placeholder:text-primary-200;
  }
}
</style>
