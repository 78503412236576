<i18n lang="json">
{
  "en": {
    "search": "Search",
    "location": "Location",
    "locationPlaceholder": "Where will you be camping?",
    "noResults": "No results",
    "mapArea": "Map area"
  },
  "fr": {
    "search": "Recherche",
    "location": "Location",
    "locationPlaceholder": "Où allez-vous camper ?",
    "noResults": "Pas de résultats",
    "mapArea": "Zone de la carte"
  }
}
</i18n>

<template>
  <div ref="outerContainer" class="ml-4 flex gap-2">
    <label ref="searchLabel" class="grow">
      {{ t('location') }}
      <div ref="searchInput">
        <FormText
          class="p-0 strong-1 border-none ring-0 h-fit overflow-hidden text-ellipsis self-end bg-inherit"
          :placeholder="t('locationPlaceholder')"
          v-model="query"
          @keyup="handleSearchInput"
          @focus="handleSearchInputFocus"
        />
      </div>
      <div
        v-show="showPredictions"
        ref="floatingEl"
        class="flex flex-col gap-2 w-[var(--place-search-width)] bg-white rounded-2xl p-4 overflow-hidden text-nowrap drop-shadow-md"
        :style="{ ...floatingStyles }"
      >
        <button
          v-for="prediction in predictions"
          :key="prediction.place_id"
          class="overflow-hidden text-ellipsis cursor-pointer text-left"
          @click="() => handlePlaceSelected(prediction)"
        >
          <div class="strong-1">{{ prediction.structured_formatting.main_text }}</div>
          <div class="caption text-primary-300">{{ prediction.structured_formatting.secondary_text }}</div>
        </button>
      </div>
    </label>
    <div class="bg-primary-100 self-center rounded-full p-1 cursor-pointer" @click="handleClearLocationSearchText">
      <IconXmark class="size-2" />
    </div>
    <slot :search="searchCampgrounds" />
  </div>
</template>

<script lang="ts" setup>
import { autoUpdate, useFloating } from '@floating-ui/vue';
import { onClickOutside, useResizeObserver } from '@vueuse/core';
import type { SearchQueryParamater } from '~/types/search';

const { t } = useI18n()
const autocompleteClient = usePlaceAutocomplete()
const { changed: searchLocationChanged } = useSearchLocationChanged()
const slots = useSlots()

const handleSearchInput = async (
  e: Event & {
    target: HTMLInputElement
  }
) => {
  searching.value = true
  if (e.target == null) return

  if (autocompleteClient == null) return

  if (gSessionId.value == null) {
    gSessionId.value = await newSessionToken()
  }

  predictions.value = await autocompleteClient({
    input: query.value.trim(),
    sessionToken: gSessionId.value
  })
}

const handleSearchInputFocus = () => {
  searching.value = true
}

const searchCampgrounds = async () => {
  if (getRouteBaseName() !== 'search') await localeNavigateTo({ name: 'search', query: { SearchAddress: query.value } })
}

const handleClearLocationSearchText = () => {
  query.value = ''
  predictions.value = []
}

const handlePlaceSelected = async (place: google.maps.places.AutocompletePrediction) => {
  const originalQuery = query.value
  query.value = place.description
  searching.value = false

  await updateSelectedPlace(place)
  if (!slots.default) await searchCampgrounds()
  searchLocationChanged()

  segmentTrack('Search Input Changed', {
    query: originalQuery,
    options: predictions.value.map(
      (p) => `${p.structured_formatting.main_text}\n${p.structured_formatting.secondary_text}`
    ),
    selected_option: `${place.structured_formatting.main_text}\n${place.structured_formatting.secondary_text}`
  })
}

const updateSelectedPlace = async (place: google.maps.places.AutocompletePrediction) => {
  query.value = place.description
  gSessionId.value = null
  updateSearchQuery({
    'searchaddress': query.value,
    nelat: null,
    nelng: null,
    swlat: null,
    swlng: null,
  } as Record<SearchQueryParamater, any>)
}

const setPlaceSearchWidth = () => {
  if (!outerContainer.value) return

  outerContainer.value.style.setProperty('--place-search-width', `${outerContainer.value.offsetWidth}px`)
}

const { current: currentSearchQuery, updateMultiple: updateSearchQuery, currentHasMapBounds: currentSearchHasBounds } = useSearchQuery()
const geolocationPlace = useGeolocationPlace()

const floatingEl = ref<HTMLElement>()
const searchInput = ref<HTMLElement>()
const searchLabel = ref<HTMLElement>()
const predictions = ref<google.maps.places.AutocompletePrediction[]>([])
const outerContainer = ref<HTMLElement>()
const query = ref(currentSearchHasBounds.value ? t('mapArea') : currentSearchQuery.value.SearchAddress ?? geolocationPlace.name ?? '')
const searching = ref(false)
const gSessionId = ref<google.maps.places.AutocompleteSessionToken | null>(null)
const showPredictions = computed(() => searching.value && query.value.length && predictions.value.length)

const { floatingStyles } = useFloating(searchInput, floatingEl, {
  whileElementsMounted: autoUpdate
})

onMounted(() => {
  setPlaceSearchWidth()

  useResizeObserver(outerContainer.value, () => {
    setPlaceSearchWidth()
  })

  onClickOutside(floatingEl, (event) => {
    if (!searchInput.value || !event.composedPath().includes(searchInput.value)) {
      searching.value = false
    }
  })
})

watch(() => currentSearchHasBounds.value,
(hasBounds) => {
  query.value = hasBounds ? t('mapArea') : currentSearchQuery.value.SearchAddress ?? geolocationPlace.name ?? ''
})
</script>
