const _autocompleteService = ref<google.maps.places.AutocompleteService>()

const autocompleteService = async (): Promise<google.maps.places.AutocompleteService> => {
  if (!_autocompleteService.value) {
    const { Loader } = await import('@googlemaps/js-api-loader')

    const $config = useRuntimeConfig().public.google
    const loader = new Loader({
      apiKey: $config.apiKey
    })
    const { AutocompleteService } = await loader.importLibrary('places')
    _autocompleteService.value = new AutocompleteService()
  }

  return _autocompleteService.value
}

const autocomplete = async (
  request: Parameters<typeof google.maps.places.AutocompleteService.prototype.getPlacePredictions>[0]
): Promise<google.maps.places.AutocompletePrediction[]> => {
  if (process.server) throw new Error('This package can only be used client side')

  try {
    if (!request.input) return []

    const service = await autocompleteService()

    if (!service) throw new Error('Autocomplete service did not load')

    if (request.componentRestrictions == null) request.componentRestrictions = { country: null }
    if (request.componentRestrictions.country == null) request.componentRestrictions.country = ['ca', 'us']

    const response = await service.getPlacePredictions(request)

    return response.predictions
  } catch (e) {
    captureError(e as Error)
    return []
  }
}

export default () => autocomplete

export const newSessionToken = async () => {
  const service = await autocompleteService()

  if (!service) throw new Error('Autocomplete service did not load')

  return new google.maps.places.AutocompleteSessionToken()
}
